
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import ApiCalls from '@/services/api-calls';
import Item from '@/components/Item.vue';
import Pagination from '@/components/Pagination.vue';
import Loader from '@/components/Loader.vue';
import { ItemDisplay } from '@/models';
import store from '@/store';

@Options({
  components: { Item, Pagination, Loader },
})
export default class Collection extends Vue {
  api = new ApiCalls();

  tags: string[] | null = null;

  selectedTags: string[] = [];

  filter = '';

  items: ItemDisplay[] | null = null;

  pagination = {
    currentPage: 0,
    itemsCount: 0,
  };

  isLoading = false;

  created(): void {
    this.api.getTags().then((response) => {
      this.tags = response.map((x: any) => x['o:id']);
    });
  }

  mounted(): void {
    console.log('params tags', this.$route.params.tags);
    this.selectedTags = this.$route.params.tags ? (this.$route.params.tags as string).split(',') : [];
  }

  @Watch('selectedTags', { immediate: true })
  onSelectedTagChange(tags: string): void {
    if (tags.length) {
      this.getItemsByTags();
    } else {
      this.items = [];
    }
  }

  isPassingFilter(tag: string): boolean {
    if (this.selectedTags.includes(tag) || (this.filter && !tag.includes(this.filter))) return false;
    return true;
  }

  onToggleTag(tag: string) {
    this.selectedTags = this.selectedTags.includes(tag) ? this.selectedTags.filter((x) => x !== tag) : this.selectedTags.concat([tag]);
    this.$router.push(`/tags/${this.selectedTags.join(',')}`);
  }

  onSelectPage(page: number) {
    this.getItemsByTags(page);
  }

  getItemsByTags(page = 0): void {
    this.items = [];
    this.isLoading = true;
    this.api.getItemsByTags(this.selectedTags, store.getters.getItemsPerPage, page).then((response) => {
      console.log('r', response);
      this.pagination = {
        currentPage: page,
        itemsCount: response.itemsCount,
      };
      this.items = response.items.map((x: any) => ({
        id: x['o:id'],
        title: x['o:title'],
        thumbnail: x.thumbnail_display_urls.square,
      }));
      console.log(this.items);
      this.isLoading = false;
    });
  }

  onSelectItem(id: number) {
    this.$router.push(`/item/${id}`);
  }
}
